module.exports = [{
      plugin: require('/home/anliksim/codebase/bsc-docs/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/home/anliksim/codebase/bsc-docs/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":740},
    },{
      plugin: require('/home/anliksim/codebase/bsc-docs/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/anliksim/codebase/bsc-docs/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludeRegex":{},"excludePattern":"excluded-link"},
    },{
      plugin: require('/home/anliksim/codebase/bsc-docs/node_modules/gatsby-plugin-glamor/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/anliksim/codebase/bsc-docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
